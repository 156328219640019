var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("테마 상세")]), _c('v-spacer'), _c('v-icon', {
    staticClass: "mr-3",
    on: {
      "click": function ($event) {
        return _vm.$emit('edit');
      }
    }
  }, [_vm._v(_vm._s(_vm.mdiCog))]), _c('v-icon', {
    staticClass: "mr-3",
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("mdi-delete")])], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "elevation-3 mx-3 v-responsive d-flex align-center justify-center",
    attrs: {
      "min-height": "256"
    }
  }, [_vm.theme.images[_vm.previewIndex] ? _c('v-img', {
    attrs: {
      "src": _vm.theme.images[_vm.previewIndex],
      "width": "100%",
      "contain": ""
    }
  }) : _vm._e()], 1), _c('v-row', {
    staticClass: "px-5 mt-2"
  }, _vm._l(_vm.theme.images, function (image, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0 v-responsive"
    }, [_c('v-responsive', {
      staticClass: "pa-1",
      attrs: {
        "aspect-ratio": 1 / 1
      },
      on: {
        "click": function ($event) {
          _vm.previewIndex = index;
        }
      }
    }, [_c('v-sheet', {
      staticClass: "elevation-3 d-flex align-center justify-center",
      attrs: {
        "width": "100%",
        "height": "100%"
      }
    }, [image ? _c('v-img', {
      attrs: {
        "src": _vm.theme.images[index],
        "max-width": "100%",
        "max-height": "100%",
        "contain": ""
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-text-field', {
    attrs: {
      "label": "테마 명칭",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.theme.name,
      callback: function ($$v) {
        _vm.$set(_vm.theme, "name", $$v);
      },
      expression: "theme.name"
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": "테마 설명",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.theme.description,
      callback: function ($$v) {
        _vm.$set(_vm.theme, "description", $$v);
      },
      expression: "theme.description"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.select
    }
  }, [_vm._v("적용")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }