var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', _vm._b({
    staticClass: "overflow-visible",
    attrs: {
      "width": "100%",
      "height": "auto"
    }
  }, 'v-responsive', _vm.$attrs, false), [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }