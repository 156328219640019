<template>
    <console-content max-width="1024">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">테마 상세</v-col>
            <v-spacer/>
            <v-icon class="mr-3" @click="$emit('edit')">{{ mdiCog }}</v-icon>
            <v-icon class="mr-3" @click="remove">mdi-delete</v-icon>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" md="6">
                <v-sheet min-height="256" class="elevation-3 mx-3 v-responsive d-flex align-center justify-center">
                    <v-img v-if="theme.images[previewIndex]" :src="theme.images[previewIndex]" width="100%" contain/>
                </v-sheet>
                <v-row class="px-5 mt-2">
                    <v-col v-for="(image, index) in theme.images" :key="index" class="pa-0 v-responsive">
                        <v-responsive :aspect-ratio="1/1" @click="previewIndex = index" class="pa-1">
                            <v-sheet class="elevation-3 d-flex align-center justify-center" width="100%" height="100%">
                                <v-img v-if="image" :src="theme.images[index]" max-width="100%" max-height="100%" contain/>
                            </v-sheet>
                        </v-responsive>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="6">
                <div class="subtitle-2 font-weight-bold">기본정보</div>
                <v-text-field v-model="theme.name" label="테마 명칭" placeholder=" " hide-details readonly />
                <v-textarea v-model="theme.description" label="테마 설명" placeholder=" " hide-details class="mt-4" readonly/>
            </v-col>
        </v-row>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="select">적용</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import { mdiCog } from "@mdi/js";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            mdiCog,
            previewIndex: 0,
            
            theme: {
                _id: this.$route.params._theme
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.theme._id){
                let { theme } = await api.console.themes.get(this.theme);
                this.theme = theme;
            }
        },
        async save(){
            this.theme._id ? await api.console.themes.put() : await api.console.themes.post();
            alert("저장되었습니다");
            this.$router.go(-1);
        },
        async select(){
            await api.console.common.setting.put({ _theme: this.theme._id });
            alert("적용되었습니다");
        },
        async remove(){
            if(confirm("삭제하시겠습니까?")){
                await api.console.themes.delete(this.theme);
                alert("삭제되었습니다");
                this.$router.go(-1);
            }
        }
    }
}
</script>